<template>
  <Container>
    <Testimonial
      :type="color"
      layout="with-border"
      :author="author"
      :src="image"
      :alt="imageAlt"
    >
      {{ body }}
    </Testimonial>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      color() {
        return this.getValue("color")
      },
      body() {
        return this.getValue("body")
      },
      author() {
        return this.getValue("author")
      },
      image() {
        return this.getValue("image")
      },
      imageAlt() {
        return this.getIngredient("image").altText
      },
    },
  }
</script>
